<template>
  <div class="calculator rt-container">
    <div class="container-fluid">
      <div class="row shadow-sm m-1 rounded-top">
        <div class="col">
          <h3 class="text-headline h4">Рассчитать стоимость услуг</h3>
        </div>
      </div>
      <form id="formX" method="POST" action="javascript:void(null);">
        <div class="row bg-bright justify-content-center shadow-sm p-3 m-1">
          <div class="from-city col p-0 ml-3 mr-3">
            <label for="baseCity">
              <p class="h4">Откуда</p>
            </label>
            <input type="search" name="baseCity" id="baseCity" class="form-control is-valid user-field" placeholder="Пункт отправки" autocomplete="off" required="" @input="cityFrom" v-model="city_from">
            <span class="resultList">
              <ul class="search_result shadow-sm pl-0" id="baseCityResult">
                <li v-for="item in search_result_from" :key="item.id">
                  <span class="city" @click="choiceCity(item.id, 0)">{{item.label}}</span>
                </li>
              </ul>
            </span>
              <span class="popularCities" @click="choiseThisCity('Санкт-Петербург', 0)" href="#">Санкт-Петербург</span>
              <span class="popularCities" @click="choiseThisCity('Москва', 0)" href="#">Москва</span>
          </div>
          <div class="col-auto align-self-end p-0 m-0 pb-4 pt-4">
            <button type="button" class="button" id="btnReverse" @click="reverseCity">
              <img class="svg-icon" id="reserveIcon" src="./assets/img/reverse.svg" alt="Реверс">
            </button>
          </div>
          <div class="to-city col p-0 ml-3 mr-3">
            <label for="city">
              <p class="h4">Куда</p>
            </label>
            <input type="search" name="city" id="city" class="form-control user-field" placeholder="Пункт доставки" autocomplete="off" required="" @input="cityTo" v-model="city_to">
            <span class="resultList">
              <ul class="search_result shadow-sm pl-0" id="baseCityResult">
                <li v-for="item in search_result_to" :key="item.id">
                  <span class="city" @click="choiceCity(item.id, 1)">{{item.label}}</span>
                </li>
                </ul>
            </span>
            <span class="popularCities" @click="choiseThisCity('Санкт-Петербург', 1)" href="#">Санкт-Петербург</span>
            <span class="popularCities" @click="choiseThisCity('Москва', 1)" href="#">Москва</span>
          </div>
        </div>
        <div class="row shadow-sm p-3 m-1" id="user-notification">
          <div class="col">
            <p class="text-undersearch">Обратите внимание: если населенный пункт в городах Москва или Санкт-Петербург
              находится за пределами МКАД (КАД), то для корректного расчета и вызова курьера следует вводить название
              населенного пункта. Вводите "Москва" или "Санкт-Петербург" только если вам нужна доставка или забор груза
              в пределах МКАД (КАД)</p>
          </div>
        </div>
        <div class="row shadow-sm p-3 m-1" id="search-db-alert" hidden="">
          <div class="col p-0 m-0">
            <div class="alert alert-danger" role="alert" style="display:none;">
              <span id="search-alert-message"></span>
            </div>
          </div>
        </div>
        <div class="row shadow-sm p-3 m-1">
          <div class="col p-0">
            
            <div class="despatchList row p-0 m-0" v-for="(gabarit, index) in gabarits" :key="index">
              <div class="input-group shadow-sm border border-dark rounded p-0 m-0 mb-3 despatch" style="display: flex;flex-wrap: wrap;">
                  <span class="close-despatch"><img class="svg-close-btn" @click="gabaritRemove(index)" src="./assets/img/cross.svg" alt=""></span>
                  <div class="col-auto p-0" v-if="false">
                    <div class="input-group-prepend p-1 show">
                      <button type="button" class="btn border border-dark btn-block btn-outline-warning dropdown-toggle dropdown-toggle-split" @click="dropdownToggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img class="svg-icon" id="despatch-icon" src="./assets/img/box.svg" alt="box">
                      </button>
                      <div :class="show_class" style="position: absolute;will-change: transform;top: 0px;left: 0px;transform: translate3d(4px, 83px, 0px);">
                        <a class="dropdown-item" id="despatch-envelope" href="#"><img class="svg-icon" src="./assets/img/doc.svg" alt="">Документы</a>
                        <a class="dropdown-item" id="despatch-box" href="#"><img class="svg-icon" src="./assets/img/box.svg" alt="">Весовое оборудование</a>
                        <a class="dropdown-item" id="despatch-box1" href="#"><img class="svg-icon" src="./assets/img/box.svg" alt="">Запчасти</a>
                        <a class="dropdown-item" id="despatch-box2" href="#"><img class="svg-icon" src="./assets/img/box.svg" alt="">Автозапчасти</a>
                        <a class="dropdown-item" id="despatch-box3" href="#"><img class="svg-icon" src="./assets/img/box.svg" alt="">Мебель</a>
                        <a class="dropdown-item" id="despatch-box4" href="#"><img class="svg-icon" src="./assets/img/box.svg" alt="">Мебельная фурнитура</a>
                        <a class="dropdown-item" id="despatch-box5" href="#"><img class="svg-icon" src="./assets/img/box.svg" alt="">Сантехника</a>
                        
                      </div>
                    </div>
                  </div>
                  <div class="col p-0">
                    <div class="prm p-1">
                        <label for="weight">Характер груза<span style="color:red">*</span></label>
                        <select class="form-control user-field" v-model="type_doc">
                          <option value="">Не выбрано</option>
                          <option :value="instrument.label" v-for="(instrument,index) in instruments" :key="index">{{ instrument.label }}</option>
                        </select>
                    </div>
                  </div>
                  <div class="col p-0">
                    <div class="prm p-1">
                      <label for="weight">Вес (кг)</label>
                      <input type="text" name="weight" id="weight" class="form-control user-field" pattern="\d+((,|\.)\d+)?" @input="windows_float" v-model="gabarit.weight" required="">
                    </div>
                  </div>            
                  <div class="col roll-prms p-0">
                    <div class="prm p-1">
                      <label for="roll-length">Длина (см)</label>
                      <input type="text" name="roll-length" id="roll-length" class="form-control user-field" pattern="\d+((,|\.)\d+)?" @input="windows_float" v-model="gabarit.length" required="">
                    </div>
                  </div>
                  <div class="col box-prms p-0">
                    <div class="prm p-1">
                      <label for="box-width">Ширина (см)</label>
                      <input type="text" name="box-width" id="box-width" class="form-control user-field" pattern="\d+((,|\.)\d+)?" @input="windows_float" v-model="gabarit.width" required="">
                    </div>
                  </div>
                  <div class="col box-prms p-0">
                    <div class="prm p-1">
                      <label for="box-height">Высота (см)</label>
                      <input type="text" name="box-height" id="box-height" class="form-control user-field" pattern="\d+((,|\.)\d+)?" @input="windows_float" v-model="gabarit.height" required="">
                    </div>
                  </div>
                  <div class="col p-0">
                    <div class="prm p-1">
                      <label for="amount">Кол-во (шт)</label>
                      <input type="text" name="amount" id="amount" class="form-control user-field" pattern="\d+" v-model="gabarit.amount" required="">
                    </div>
                  </div>
              </div>
            </div>

            <div class="row justify-content-center p-0 m-0 mt-1">
              <div class="col p-0">
                <button type="button" class="button-add" @click="btnAddDespatch">
                  <img class="svg-icon" src="./assets/img/plus.svg" alt="Добавить место">
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row shadow-sm justify-content-center p-3 m-1">
          <div class="col-auto">
            <input type="submit" class="button button-calculate" @click="btnCalculate" value="Рассчитать">
          </div>
          <span class="close-despatch"></span>
        </div>

      </form>
      <div class="row shadow-sm p-3 m-1" id="calculate-db-alert" v-if="danger">
        <div class="col p-0 m-0">
          <div class="alert alert-danger" role="alert">
            <span id="calculate-alert-message">{{danger}}</span>
          </div>
        </div>
      </div>
      <div class="row shadow-sm p-3 m-1" id="calculate-result" v-show="result_data.length">
        <div class="col p-0 m-0">
          <div class="otkuda_kuda">Доставка из города: {{city_from}} в город: {{city_to}}</div>
          <div class="table">
            <div class="bg-bright row-parent">
              <div scope="col" class="mode">Услуга (Срочность доставки)</div>
              <div scope="col" class="mode">Срок доставки, раб.Дней</div>
              <div scope="col" class="mode">Стоимость доставки, руб.</div>
            </div>
            <div class="body-results">
              <div class="row-parent" v-for="(item, index) in result_data" :key="index">
                <div scope="col" class="mode">{{item.type}}</div>
                <div scope="col" class="mode" v-if="item.start || item.end">{{item.start}} - {{item.end}}</div>
                <div scope="col" class="mode" v-else>Звоните</div>
                <div scope="col" class="mode" v-if="item.price && item.price != 0">{{floatString(item.price)}} <span class="ruble" style="display:inline-block;margin-left:3px;">₽</span></div>
                <div scope="col" class="mode" v-else>Звоните</div>
              </div>
            </div>
          </div>
          <div class="block_curier">
            <a class="button" target="blank" href="https://delserv.ru/klientu/on-line-servisy/courier.html">Вызвать курьера</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import Treeselect from 'vue3-treeselect'
  // import the styles
  // import 'vue3-treeselect/dist/vue3-treeselect.css'
export default {
  name: 'App',
  // components: { autocomplete },
  data(){
    return {
      prep_api_url: 'https://delserv.ru/calctwo/php/index.php',
      instruments: [],
      type_doc: "",
      gabarits: [],
      show_dropdown: false,      
      city_to: "",
      city_from: "",
      search_result_to:[],
      search_result_from:[],
      options_to: [],
      options_from: [],
      id_to: 0,
      id_from: 0,
      stop_search_to: false,
      stop_search_from: false,
      result_data: [],
      danger: ""
    }
      
  },
  computed:{
      show_class(){
        if(this.show_dropdown) return 'dropdown-menu show';
        return  'dropdown-menu';
      }
  },
  methods:{
    windows_float(e){
      e.target.value = e.target.value.replace(/,/g, '.') 
    },
    dropdownToggle(){
        this.show_dropdown = !this.show_dropdown;
    },
    loadResponsiblesOptions(){
      axios(this.prep_api_url+"?type=cities_from",)
        .then(response => {
            this.options_from = response.data;
            console.log(this.options_from);
        })
        .catch( error => {
            console.log(error);
        });

      axios(this.prep_api_url+"?type=cities_to",)
        .then(response => {
            this.options_to = response.data;
        })
        .catch( error => {
            console.log(error);
        });

      axios(this.prep_api_url+"?type=instruments",)
        .then(response => {
            this.instruments = response.data;
            console.log(this.instruments);
        })
        .catch( error => {
            console.log(error);
        });
    },
    btnAddDespatch(){
      this.gabarits.push({"weight": '', "length": '', "width": '', "height": '', "amount": ''});
    },
    gabaritRemove(index){
      this.gabarits.splice(index, 1)
    },
    floatString(val){
      return parseFloat(val).toFixed(2);
    },
    callCourier(){
      alert("Алло, это курьер?");
    },
    btnCalculate(){
      event.preventDefault();
      this.result_data = [];
      this.danger = "";
      if(!this.type_doc){
        this.danger = "Не выбран Характер груза";
        return false;
      }
      let result = 0;
      this.gabarits.map(function(item){
        let volume_weight = (parseFloat(item.length) * parseFloat(item.width) * parseFloat(item.height)) / 5000;
        let pay_weight = parseFloat(item.weight);
        if(volume_weight > parseFloat(item.weight)){
          pay_weight = volume_weight;
        }
        result += pay_weight * parseInt(item.amount);
      });
      if(result > 0.5){
        result = Math.ceil(result)
      }
      let url = this.prep_api_url+"?type=zrank"+"&weight="+result+"&id_from="+this.id_from+"&id_to="+this.id_to;
      axios(url,)
        .then(response => {
            this.result_data = response.data;
            if(!this.result_data.length){
              this.danger = "Для расчета по данному направлению обратитесь к операторам!";
            }

        })
        .catch( error => {
            console.log(error);
        });
     
    },
    reverseCity(){
      this.result_data = [];
      let id_to = this.id_from;
      this.choiceCity(this.id_to, 0);
      this.choiceCity(id_to, 1);
    },
    choiseThisCity(name, type){
      this.result_data = [];
      let url = this.prep_api_url+"?type=zcitythis"+"&name="+name;
      axios(url,)
        .then(response => {
            this.choiceCity(response.data.id, type);

        })
        .catch( error => {
            console.log(error);
        });
    },
    choiceCity(index, type){
      // откуда
      if(!type){
        this.stop_search_from = true;
        this.options_from.map((item) => {
          if(item.id == index){
            this.id_from = index;
            this.city_from = item.label;
          }
        });
        this.search_result_from = [];
        // this.stop_search_from = false;
      }
      else{// куда
        this.stop_search_to = true;
        this.options_to.map((item) => {
          if(item.id == index){
            this.id_to = index;
            this.city_to = item.label;
          }
        });
        this.search_result_to = []
        // this.stop_search_to = false;
      }
    },
    cityFrom(e){
      let val = e.target.value;
      this.id_from = 0;
      this.result_data = [];
      if(val.length < 3)
            this.stop_search_from = false; 
      if(this.stop_search_from) return;
      this.search_result_from = [];
        this.options_from.map((item) => {
          if (item.label.indexOf(val.toUpperCase()) >= 0)
            this.search_result_from.push(item)
          
        });
    },
    cityTo(e){
      let val = e.target.value;
      this.id_to = 0;
      this.result_data = [];
      if(val.length < 3)
            this.stop_search_to = false; 
      if(this.stop_search_to) return;
      this.search_result_to = [];
        this.options_from.map((item) => {
          if (item.label.indexOf(val.toUpperCase()) >= 0)
            this.search_result_to.push(item)
          
        });
    }
  },
  watch: {
    // city_to: {
    //     handler: function (val) {
    //       // alert()
    //         this.result_data = [];
    //         if(val.length < 3)
    //           this.stop_search_to = false; 
            
    //         if(this.stop_search_to) return;
            
    //         this.search_result_to = [];
    //         this.options_to.map((item) => {
    //           if (item.label.indexOf(val.toUpperCase()) >= 0)
    //             this.search_result_to.push(item)
              
    //         });
            
    //     },
    //     deep: true
    //   }

    
  },
  beforeMount() {
    this.gabarits.push({"weight": 0.5, "length": 21, "width": 31, "height": 1, "amount": 1});
    this.loadResponsiblesOptions();
  }
}
</script>
